.nav-logo-container {
  img {
    width: 40%;
  }
}

.nav {
  a {
    &.contact {
      background: #d92909;
      color: #fff !important;
      padding: 8px 14px;
      border-radius: 10px;
    }
  }
}

.btn-primary {
  background-image: linear-gradient(45deg, #129b8d, #38ef7d);
  color: #fff !important;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 10px;
}

.socialicons {
  img {
    padding-right: 10px;
  }
  i {
    font-size: 20px;
    padding-right: 10px;
  }
}

.primary-text {
  color: #2c6ac7;
}

.ptb-7 {
  padding: 70px 0px;
}

.footer {
  background-color: #2c6ac7;
}

a {
  color: #2c6ac7 !important;
  text-decoration: none !important;
}

.social-icons {
  a {
    margin-left: 10px;
    img {
      width: 24px;
    }
  }
}

.themebtn {
  // position: fixed;
  // top: 50%;
  // right: 0;
  // transform: rotate(-90deg);
  padding: 5px !important;
  margin-left: 40px;
  background-color: #f8f8f8 !important;
  border: 0;
  border-radius: 5px;
  border: 1px solid transparent;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #000 !important;
    border: 1px solid #fff;
    transition: 0.6s ease;
    svg {
      color: #fff;
    }
  }
}

.wcindia {
  position: relative;
  .pos-abs {
    background: #2c6ac7;
    padding: 20px;
    color: #fff !important;
    /* font-weight: 700; */
    position: absolute;
    width: 500px;
    border-radius: 20px;
    font-size: 20px;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    a {
      color: #fff !important;
      text-decoration: underline !important;
      font-weight: 700;
    }
  }
}

.primbtn {
  background: #d92909;
  color: #fff !important;
  padding: 8px 14px;
  font-family: "Inter", sans-serif;
  transition: 0.2s ease all;
  border-radius: 10px;
  border: 1px solid transparent;
  font-size: 14px;
  &:hover {
    background-color: #fff;
    color: #000 !important;
    border: 1px solid #ddd;
  }
}

.hero {
  // background-image: url("../img/bg.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  width: 100%;
  box-shadow: 0px 24px 44px 0px rgba(0, 0, 0, 0.05);
}

.navbar-menu-container {
  color: #2c6ac7 !important;
}
.footer {
  a,
  h5,
  h6,
  small {
    color: #fff !important;
    img {
      width: 30%;
    }
  }
}

.destinations {
  .seccards {
    .item {
      img {
        &.arw {
          width: 20%;
        }
      }
      .head {
        p {
          &.dest {
            font-size: 24px !important;
            text-transform: none;
            font-weight: 500;
            color: #fff;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.services {
  .item {
    background: #2c6ac7;
    padding: 40px 20px;
    border-radius: 20px;
    &:hover {
      background: #0a46a0;
    }
    p {
      color: #fff;
      font-weight: 700;
      font-size: 22px;
    }
    p {
      &.link {
        color: #fff !important;
        text-decoration: underline !important;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
}

.cta {
  background: #b2ccff7a;
  border-radius: 30px;
}

.bgSec {
  &.sec3padding {
    padding-bottom: 350px;
    background-color: #e9edf5;
  }

  p {
    &.cursive {
      font-family: "Inter", sans-serif;
      font-size: 30px !important;
      line-height: 26px;
    }
  }
  .seccards {
    &.celeb {
      padding-top: 150px;
    }

    .item {
      position: relative;
      overflow: hidden;
      transition: 0.6s all ease;
      &:hover {
        transition: 0.6s all ease;
        img {
          filter: grayscale(1);
        }
      }
      img {
        width: 100%;
      }
      &.oasis {
        &:hover {
          .head {
            p {
              opacity: 0;
            }
            .visible {
              opacity: 1;
            }
          }
        }
      }
      .head {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 70%;
        .visible {
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        p {
          font-size: 28px !important;
          text-transform: none;
          color: #fff;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.backbtn {
  padding: 7px 15px;
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid transparent;
  align-items: center;
  display: flex;
  gap: 10px;
  background-color: #2c6ac70f;
}

.programs-menu {
  &.active {
    span {
      color: #2c6ac7 !important;
      font-weight: 600;
    }
  }
}

.courses {
  padding: 20px 0px 30px;
  border-bottom: 1px solid #eeeeee78;

  &.nopopup {
    .items {
      .item {
        background-color: #d5e1f4 !important;
        border: 1px solid #2c6ac7;
        font-weight: 400;
        color: #000 !important;
        &:hover {
          cursor: auto;
          background-color: #d5e1f4 !important;
        }
      }
    }
  }

  h4 {
    color: #2c6ac7 !important;
    margin-bottom: 20px;
  }

  .items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .item {
      background: #2c6ac7 !important;
      color: #fff !important;
      font-weight: 600;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover,
      &.active {
        background-color: #1a4e8c !important;
      }
    }
  }

  .popup-box {
    position: absolute;
    background: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 1000px;
    text-align: left;

    .items {
      align-items: center;
      padding-bottom: 10px;
      .item {
        background-color: rgba(147, 216, 122, 0.6) !important;
        border-radius: 7px;
        font-size: 16px;
        color: #000 !important;
        font-weight: 400;
        padding: 3px 10px;
      }
    }

    h5 {
      margin: 0;
      color: #2c6ac7;
    }

    p {
      margin: 10px 0 0;
      color: #666;
    }

    .close-icon {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: red;
      }
    }
  }
}

/* Disable clicks on the rest of the page */
.disable-clicks {
  pointer-events: none;

  .popup-box,
  .popup-box * {
    pointer-events: auto; /* Allow clicks within the popup */
  }
}

.uni-color {
  color: #d92909 !important;
}

ul {
  &.unilist {
    padding-left: 0px !important;
  }
  li {
    list-style: none;
    font-size: 18px;
  }
}
// Rooms SCSS

.mt-10 {
  margin-top: 100px;
}

.hotel-room {
  display: flex;
  border: 1px solid #efefef;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  // margin-top: 100px;

  .room-image {
    flex: 1;
    width: 40%;

    .carousel {
      .slide {
        img {
          object-fit: cover;
          height: 400px; // Adjust as needed
          width: 100%; // Ensure image fits the container
        }
      }

      .control-arrow {
        background: rgba(0, 0, 0, 0.5);
        width: 40px;
        height: 40px;
        position: absolute;
        top: 45%;

        &:before {
          border-width: 12px 12px 12px 0;
        }
        &:after {
          border-width: 12px 12px 12px 0;
        }

        &.control-prev {
          &:before {
            border-right-color: white; // Customize arrow color
            border-width: 5px 5px 5px 0;
            position: absolute;
            left: 12px;
            bottom: 13px;
          }
        }

        &.control-next {
          &:before {
            border-left-color: white; // Customize arrow color
            border-width: 5px 0px 5px 5px;
            position: absolute;
            left: 12px;
            bottom: 13px;
          }
        }
      }
    }
  }

  .room-details {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;

    .room-title {
      font-size: 34px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .room-description {
      font-size: 20px !important;
      margin-bottom: 30px;
    }

    .room-price {
      font-size: 20px;
      font-weight: bold;

      span {
        font-size: 16px;
        color: #888;
      }
      p {
        &.note {
          font-weight: normal;
          color: #888;
        }
      }
    }

    .room-amenities {
      margin-top: 20px;

      h5 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .amenity {
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 10px;
          gap: 10px;
          font-size: 24px;

          i {
            position: relative;
            top: -4px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.footer {
  h6 {
    text-decoration: underline;
  }
  ul {
    li {
      padding-bottom: 10px;
    }
  }
}

.contact-section {
  // padding: 40px 0;
  padding: 70px 0px;
  text-align: center;

  h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .contact-list {
    list-style: none;
    padding: 0;

    .contact-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-bottom: 15px;

      svg {
        margin-right: 10px;
        font-size: 24px;
        color: #2c6ac7;
      }

      span {
        font-size: 18px;

        a {
          color: #2c6ac7;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.admissions {
  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      margin-bottom: 0px;
      width: 70px;
      text-align: right;
    }
    a {
      color: #d92909 !important;
      font-weight: 700;
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 9;
}

.pcta {
  a {
    position: relative;
    h4 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #fff !important;
      font-weight: 400 !important;
      width: 100%;
    }
  }
}

.popup-box .success-message {
  text-align: center;
  padding: 20px;
}

.popup-box .success-message h3 {
  color: green;
  margin-bottom: 10px;
}

.popup-box .success-message p {
  font-size: 16px;
}

.programs-menu {
  position: relative;
  span {
    color: #333;
    margin: 0px 20px;
  }

  .mega-menu {
    position: absolute;
    top: 100%;
    // left: 50%; /* Center the mega menu */
    width: max-content; /* Make it responsive */
    // max-width: 1100px; /* Ensure it doesn't exceed the max width */
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    border-radius: 10px;
    box-sizing: border-box;
    transform: translateX(20%); /* Center the mega menu */
    right: 0;
    margin-top: 10px;

    .mega-menu-content {
      display: flex;
      gap: 20px;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between;

      .mega-card {
        border: 1px solid transparent;
        padding: 10px 0px;
        border-radius: 10px;
        transition: 0.6s ease all;
        width: max-content; /* Ensure cards are responsive */
        a {
          ul {
            li {
              cursor: initial;
              &:hover {
                color: inherit;
              }
            }
          }
        }

        &:hover {
          border: 1px solid #ddd;
        }

        h5 {
          font-size: 16px;
          color: #2c6ac7;
          margin-bottom: 10px;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            font-size: 14px;
            margin-bottom: 5px;

            &:hover {
              color: #2c6ac7;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  // /* Media Queries for responsiveness */
  @media (max-width: 1024px) {
    .mega-menu {
      // height: 500px;
      // overflow-y: auto;
      transform: translate(20%);
      width: 800px;
    }
  }

  // /* Media Query for Tablets */
  // @media (max-width: 768px) {
  //   .mega-menu {
  //     width: 80%; /* Adjust width for tablets */
  //     max-width: 90%;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }

  //   .mega-menu-content {
  //     flex-direction: column; /* Stack cards vertically on tablets */
  //     gap: 10px;
  //   }

  //   .mega-card {
  //     width: 100%; /* Ensure cards take full width */
  //   }
  // }

  // /* Media Query for Mobile Phones */
  // @media (max-width: 480px) {
  //   .mega-menu {
  //     width: 100%; /* Make it full width on mobile */
  //     max-width: 100%;
  //     left: 0;
  //     transform: none; /* Reset centering */
  //   }

  //   .mega-menu-content {
  //     flex-direction: column; /* Stack cards vertically on mobile */
  //     gap: 15px;
  //   }

  //   .mega-card {
  //     width: 100%; /* Ensure cards take full width on mobile */
  //   }
  // }
}
