body {
  font-family: "Inter", sans-serif;
  color: #000 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  font-family: "Inter", sans-serif;
  color: #000 !important;
}

.font-18 {
  font-size: 18px !important;
}

.cursive {
  font-family: "Inter", sans-serif;
  font-size: 38px;
  line-height: 40px;
}

.box-shadow {
  box-shadow: 10px 10px 20px #00000017;
}

.header {
  padding: 10px 0px;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 10px 10px #00000008;
}

.ptb-5 {
  padding: 50px 0px;
}

p {
  font-size: 18px;
}

.App {
  padding-top: 100px !important;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 90px; */
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  font-family: "Inter", sans-serif;
  margin: 0px 20px;
  text-decoration: none;
  color: #333 !important;
  font-size: 15px;
}

.navbar-links-container a.active {
  color: #2c6ac7 !important;
  font-weight: 600;
}

.navbar-links-container a.navhighlight {
  font-size: 20px !important;
}

.section {
  padding: 70px 0px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:vertical {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2c6ac7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c82b60;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.themebtn {
  display: none;
}

.floating-item {
  animation: floatAnimation 3s ease-in-out infinite;
}

@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
    font-size: 26px;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }

  .mobcenter {
    text-align: center !important;
  }

  .mobnone {
    display: none;
  }

  .mobdarktext {
    color: #333 !important;
  }

  .whychooseus {
    padding: 80px 0px 0px !important;
    background: #333;
    background-image: none;
  }

  .banner1 {
    background: #e0f2f1;
    padding-bottom: 20px;
  }

  .banner {
    background: #2c6ac7;
    background-image: none !important;
  }

  .mobpb {
    padding-bottom: 3rem !important;
  }
}
