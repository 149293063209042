/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup Box */
.popup-box {
  background: #0056b3;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 20px;
  text-align: center;
}

.popup-form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

/* Close Button */
.popup-close {
  position: absolute;
  top: -5px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #fff !important;
}

/* Popup Content */
.popup-content h3 {
  margin: 10px 0;
  color: #fff !important;
}

.popup-content p {
  color: #fff;
}

.popup-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Popup Form */
.popup-form .form-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.popup-form input,
.popup-form textarea,
.popup-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.popup-form textarea {
  resize: none;
}

.popup-form button {
  width: 100%;
  padding: 10px;
  background: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.popup-form button:hover {
  background: #003c82;
}
