@media (max-width: 768px) {
  .section {
    padding: 50px 0px;
  }

  .ptb-5 {
    padding: 30px 0px;
  }

  .nav-logo-container img {
    width: 70%;
  }

  .destinations .seccards .item .head p.dest {
    font-size: 20px;
  }

  .App {
    padding-top: 80px !important;
  }

  .courses .items .item {
    font-size: 14px;
  }
  .courses .items {
    gap: 10px !important;
  }

  .courses {
    padding: 10px 0px 20px;
  }

  .courses h4 {
    margin-bottom: 10px;
  }

  .popup-content {
    h3 {
      font-size: 18px;
    }

    img {
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }

  .pcta {
    gap: 10px;
  }

  .popup-form .form-group {
    // flex-direction: column;
    &:nth-child(2) {
      flex-direction: column;
    }
  }

  .ptb-7 {
    padding: 40px 0px;
  }

  .wcindia {
    .pos-abs {
      position: initial;
      transform: none;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 10px;
    }
  }

  .cursive {
    font-size: 30px !important;
  }

  .services {
    &.ptb-5 {
      padding: 30px 0px 0px;
    }
    .item {
      padding: 10px;
      min-height: 140px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
        padding-bottom: 5px !important;
      }
      p {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px !important;
        line-height: 24px;
        &.link {
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

  .navbar-links-container,
  .mob-no {
    display: none !important;
  }

  .programs {
    img {
      height: 140px;
      object-fit: cover;
      border-radius: 20px;
    }
    .bgSec {
      .seccards {
        .item {
          .head {
            // top: 45%;
            p {
              font-size: 20px !important;
              text-transform: none;
              color: #fff;
              margin-bottom: 0px;
              line-height: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .mobcenter {
    text-align: center;
  }

  .mp-0 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .mb-none {
    display: none;
  }

  .bgSec {
    p {
      &.cursive {
        font-family: "Inter", sans-serif;
        font-size: 25px !important;
        line-height: 32px;
      }
    }
  }

  .hotel-room {
    flex-direction: column !important;
    .room-image {
      flex: 1;
      width: 100%;
    }
  }

  .carousel {
    .thumbs {
      text-align: center;
    }
    .room-details {
      padding-top: 0px;
    }
    .thumbs-wrapper {
      ul {
        li {
          width: 50px !important;
        }
      }
    }
  }

  .footer {
    padding-top: 50px;
  }

  .bgSec {
    .seccards {
      .item {
        padding-bottom: 20px;

        .head {
          top: 45%;
          .visible {
            bottom: -30px;
            opacity: 1;
          }
        }
      }
      &.celeb {
        padding-top: 20px;
      }
    }

    .sec3 {
      height: 100%;
    }

    &.sec3padding {
      padding-bottom: 0px;
    }
  }
}
